import React from 'react'
import { Card, CardSet } from '../shared/cards'
import { Section, Subsection, Subtitle, Title } from './section'

const EmailMarketing = ({ className }) => (
  <Section id="email-marketing" className={className}>
    <Title>Email marketing</Title>

    <Subsection>
      <Subtitle>Contacts</Subtitle>
      <CardSet storageKey="pricing-email-marketing-contacts-carousel">
        <Card title="Contact database">
          Keep all your leads generated via Getform in one place. Access your
          contact database, review interaction history, add tags, and build
          audience segments.
        </Card>
        <Card title="Segments">
          Improve your email marketing ROI with segmentation. Use tags,
          locations, and other data collected via your opt-in forms to create
          personalized campaigns.
        </Card>
        <Card title="Tags">
          Segment your audience using tags. Assign tags via field mapping and
          automations; add and remove tags manually right in your contact
          database.
        </Card>
        <Card title="Interaction history">
          View each contact's interaction history. Find out when and how they
          joined your list, where they are from, and whether they've interacted
          with your emails.
        </Card>
        <Card title="Import">
          Import a list of your contacts to Getform. Upload emails with custom
          fields via a CSV file, add them manually, or import from Mailchimp or
          Klaviyo.
        </Card>
        <Card title="Custom fields">
          Create and manage custom contact fields. Use them for segmentation,
          contact filtering, and dynamic text replacement.
        </Card>
      </CardSet>
    </Subsection>

    <Subsection>
      <Subtitle>Broadcasts</Subtitle>
      <CardSet storageKey="pricing-email-marketing-broadcasts-carousel">
        <Card title="Audience targeting">
          Set up audience filters and conditions to send targeted email
          campaigns.
        </Card>
        <Card title="Targeting templates">
          Use built-in targeting templates to quickly build segments for your
          campaign.
        </Card>
        <Card title="Performance report">
          See how many people have received, opened, and clicked through your
          email.
        </Card>
        <Card title="Scheduling">
          Send email broadcasts right away or schedule them for later.
        </Card>
        <Card title="AI smart sending" badge="pro">
          Use AI predictions to send emails at optimal times to boost open rates
          and CTR.
        </Card>
      </CardSet>
    </Subsection>

    <Subsection>
      <Subtitle>Automations</Subtitle>
      <CardSet storageKey="pricing-email-marketing-automations-carousel">
        <Card title="Form submission">
          Set up follow-up automations to confirm any form submission and tag
          new contacts.
        </Card>
        <Card title="Email link click">
          Build automations triggered when contacts click certain links in your
          emails.
        </Card>
        <Card title="Webhook request">
          Trigger email marketing workflows by real-time events and incoming
          data.
        </Card>
        <Card title="Segment">
          Launch automations for the contacts joining specific segments.
        </Card>
        <Card title="Workflow">
          Build a reusable workflow and launch it manually or within other
          automations.
        </Card>
      </CardSet>
    </Subsection>

    <Subsection>
      <Subtitle>Features</Subtitle>
      <CardSet storageKey="pricing-email-marketing-features-carousel">
        <Card title="Full-featured email editor">
          Use the built-in email editor to design beautiful emails with your
          branding.
        </Card>
        <Card title="Email draft sharing">
          Share email drafts with your teammates before sending them to
          subscribers.
        </Card>
        <Card title="Template gallery">
          Get started faster with a gallery of customizable email templates.
        </Card>
        <Card title="AI text generator">
          Quickly generate text for your emails from scratch or refine your own
          copy.
        </Card>
        <Card title="Dynamic text replacement">
          Use names, locations, current date, and other available data in your
          email copy.
        </Card>
        <Card title="Custom email domains">
          Use your own email domains to boost delivery rates and enhance
          authenticity.
        </Card>
        <Card title="Intelligent warmup" badge="pro">
          Gradually increase email volume to improve delivery rates and reach
          more inboxes.
        </Card>
        <Card title="Dedicated IP addresses" badge="pro">
          Request a dedicated IP to achieve a better sender reputation and
          deliverability.
        </Card>
      </CardSet>
    </Subsection>
  </Section>
)

export default EmailMarketing
