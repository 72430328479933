import React from 'react'
import { Card, CardSet } from '../shared/cards'
import { Section, Subsection, Subtitle, Title } from './section'

const Forms = ({ className }) => (
  <Section id="forms" className={className}>
    <Title>Forms</Title>

    <Subsection>
      <Subtitle>Features</Subtitle>
      <CardSet storageKey="pricing-forms-features-carousel">
        <Card title="Full-featured form editor">
          Design online forms that are truly yours: select a layout, adjust form
          fields, swap images, and customize buttons.
        </Card>
        <Card title="Mobile-friendly forms">
          Never worry about your mobile audience: forms created in Getform
          easily adapt to screens of all sizes and always look great.
        </Card>
        <Card title="Custom subdomain">
          Add your brand name to the page URL to let your audience know it
          belongs to you: myshop.getform.com/cake-order-form
        </Card>
        <Card title="Template gallery">
          Get started faster with a gallery of ready-to-use form templates
          prepared by professional designers.
        </Card>
        <Card title="AI text generator">
          Use AI to generate text for your forms faster. Get smart suggestions
          for the form title, description, and CTA button.
        </Card>
        <Card title="Statictics & reports">
          See how many people have seen and clicked through your forms; build
          activity reports using charts and filters.
        </Card>
        <Card title="Dynamic text replacement">
          Automatically insert customers' locations, names, and current dates in
          the text of your forms and landing pages.
        </Card>
        <Card title="CSS editor" badge="pro">
          Tailor Getform mini landing pages to your branding with the built-in
          CSS editor. Adjust the sizing, spacing, font, and color theme.
        </Card>
        <Card title="Skip logic and branching" badge="pro">
          Create online forms that adapt each subsequent page to the options
          selected by respondents.
        </Card>
      </CardSet>
    </Subsection>

    <Subsection>
      <Subtitle>Integrations</Subtitle>
      <CardSet storageKey="pricing-forms-integrations-carousel">
        <Card title="Email marketing apps">
          Connect Getform with Klaviyo, Mailchimp, Kit, and other email
          marketing apps.
        </Card>
        <Card title="CRM & automation apps">
          Make use of built-in integrations with marketing platforms like
          Hubspot and Keap.
        </Card>
        <Card title="Customer support apps">
          Optimize your customer experience by connecting Intercom or Zendesk.
        </Card>
        <Card title="Third-party apps">
          Connect your forms and landing pages with Google Sheets or other apps
          via Zapier.
        </Card>
      </CardSet>
    </Subsection>
  </Section>
)

export default Forms
