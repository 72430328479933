import React from 'react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../theme'
import { H1 } from '../shared/type'
import BasePlans from './plans'

const Section = styled.section``

const Heading = styled(H1)`
  margin: 0 0 ${p => p.theme.space[8]};
`

const Description = styled.p`
  font-size: ${p => p.theme.fontSizes[3]};
  margin: ${p => `0 0 ${p.theme.space[8]}`};
  max-width: 46rem;

  ${mediaQueries.md} {
    margin: ${p => `0 0 ${p.theme.space[16]}`};
  }
`

const Plans = styled(BasePlans)`
  margin-bottom: ${p => p.theme.space[14]};

  ${mediaQueries.md} {
    margin-bottom: ${p => p.theme.space[20]};
  }
`

const PricingHero = ({ className }) => (
  <Section className={className}>
    <Heading>Pricing</Heading>
    <Description>
      All plans include online forms, email marketing automations, email
      broadcasts, form and email templates, AI writing assistant and live chat
      support.
    </Description>
    <Plans />
  </Section>
)

export default PricingHero
