import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { H2 } from '../components/shared/type'
import UiContainer from '../components/shared/container'
import { mediaQueries } from '../theme'
import Layout from '../components/layout'
import PageMetadata from '../components/page-metadata'
import FooterCta from '../components/footer-cta'
import PricingHero from '../components/pricing/pricing-hero'
import BaseEmailMarketing from '../components/pricing/email-marketing'
import BaseForms from '../components/pricing/forms'
import PricingFAQ from '../components/faq/pricing-faq'
import BaseRunningLine from '../components/home/running-line'

const sectionBottomIndents = ({ theme }) => css`
  margin-bottom: ${theme.space[21]};

  ${mediaQueries.lg} {
    margin-bottom: ${theme.space[26]};
  }
`

const sectionTopIndents = ({ theme }) => css`
  padding-top: ${theme.space[21]};

  ${mediaQueries.lg} {
    padding-top: ${theme.space[26]};
  }
`

const Container = styled(UiContainer)`
  ${sectionBottomIndents}
`

const RunningLine = styled(BaseRunningLine)``

const BottomRunningLine = styled(BaseRunningLine)`
  ${sectionBottomIndents}
`

const EmailMarketing = styled(BaseEmailMarketing)`
  ${sectionBottomIndents}
  ${sectionTopIndents}
`

const Forms = styled(BaseForms)`
  ${sectionTopIndents}
`

const Title = styled(H2)`
  margin: ${p => `0 0 ${p.theme.space[8]}`};
  font-size: ${p => p.theme.fontSizes[8]};

  ${mediaQueries.lg} {
    margin: ${p => `0 0 ${p.theme.space[15]}`};
  }
`

const PricingPage = ({ location }) => (
  <Layout location={location}>
    <PageMetadata
      title="Explore Getform Subscription Plans: Small, Medium, and Large"
      description="Getform subscription plans vary based on the number of monthly form submissions. Explore your options and pick the one that will be sufficient for your project."
      keywords="getform subscription, getform pricing"
    />
    <Container>
      <PricingHero />
    </Container>

    <RunningLine text={['Capture Leads', 'Automate Emails', 'Boost Revenue']} />

    <Container>
      <Forms />
      <EmailMarketing />
    </Container>

    <BottomRunningLine
      text={['Capture Leads', 'Automate Emails', 'Boost Revenue']}
    />

    <Container>
      <Title>FAQ</Title>
      <PricingFAQ />
    </Container>

    <FooterCta />
  </Layout>
)

export default PricingPage
